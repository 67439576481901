import React, { useState, useEffect, useContext } from "react";

import {
  Card,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Button,
  IconButton,
  Tooltip,
  Fade,
  Switch,
  Breadcrumbs,
} from "@mui/material";

import { FaBuilding } from "react-icons/fa";
import AssessmentIcon from "@mui/icons-material/Assessment";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";

import AuthContext from "../../Core/store/auth-context";
import { styled } from "@mui/material/styles";
import PageInfoBreadCrumbs from "../../Core/components/Layout/PageInfoBreadCrumbs";
import LineRaceChart from "./LineRaceChart";
import NegativeBarChart from "./NegativeBarChart";

import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import ColorConstants from "../../Core/constants/ColorConstants.json";
import Constants from "../../../Constants.json";
import { IoArrowDown, IoArrowUp } from "react-icons/io5";
import { IoArrowUpOutline } from "react-icons/io5";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import PieChart from "./PieChart";
import { useSwitch } from "../../../utils/context/SwitchContext";

import { CgSpinner } from "react-icons/cg";
import GeoChartComponent from "./charts/GeoCharts";
import HorizontalBarChart from "./charts/HorizontalBar";
import DonutPieChart from "./charts/DonoutChart";

const headYears = [
  2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023,
];

const passingHeadCells = {
  data: [
    // {
    //   label: "Strategy Name",
    //   isValueLink: false,
    //   isDropDown: false,
    // },
    {
      label: "Company Name",
      key: "company_name",
      isValueLink: false,
      isDropDown: false,
    },
    // {
    //   label: "Logo",
    //   key: "",
    //   isValueLink: false,
    //   isDropDown: false,
    // },
    {
      label: "Ticker",
      key: "symbol",
      isValueLink: false,
      isDropDown: false,
    },
    {
      label: "Exchange",
      key: "exchange",
      isValueLink: false,
      isDropDown: false,
    },
    {
      label: "Sector",
      key: "sector",
      isValueLink: false,
      isDropDown: false,
    },
    {
      label: "Industry",
      key: "industry",
      isValueLink: false,
      isDropDown: false,
    },
    {
      label: "Total Return (%)",
      key: "total_return",
      isValueLink: false,
      isDropDown: false,
    },
    {
      label: "Annualized Return (%)",
      key: "annualized_return",
      isValueLink: false,
      isDropDown: false,
    },
    {
      label: "Rolling Return (%)",
      key: "rolling_return",
      isValueLink: false,
      isDropDown: false,
    },
    {
      label: "Standard Deviation (%)",
      key: "stdev_return",
      isValueLink: false,
      isDropDown: false,
    },
    {
      label: "Max Drawdown (%)",
      key: "max_drawdown",
      isValueLink: false,
      isDropDown: false,
    },
    {
      label: "Sharpe Ratio",
      key: "sharpe_ratio",
      isValueLink: false,
      isDropDown: false,
    },
    {
      label: "Sortino Ratio",
      key: "sortino_ratio",
      isValueLink: false,
      isDropDown: false,
    },
  ],
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: ColorConstants.APP_TABLE_HEAD_COLOR,
    color: theme.palette.common.black,
    padding: 12,
    fontFamily: "Montserrat",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: 12,
    fontFamily: "Montserrat",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type()": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const headCategories1 = [
  "Avg",
  "Best",
  "Worst",
  "Negative Periods",
  "Duration",
];
const headCategories = [
  { title: "Avg", key: "rolling_return" },
  { title: "Best", key: "best_return" },
  { title: "Worst", key: "worst_return" },
  { title: "Negative Periods", key: "negative_annual_returns" },
  // { title: "Duration", key: "duration" },
];

const sortingFields = [
  { key: "none", label: "None" },
  { key: "name", label: "Strategy" },
  { key: "rolling_return", label: "Average Returns" },
  { key: "best_return", label: "Best Returns" },
  { key: "worst_return", label: "Worst Returns" },
  { key: "negative_annual_returns", label: "Negative Returns" },
];

const ReturnsTab = ({
  setSelectedCompany,
  activeButton,
  setActiveButton,
  showVisualData,
  setShowVisualData,
}) => {
  let pageLoc = window.location.pathname;

  const strategyNames = [
    "Buffett: Hangstrom",
    "Philip Fisher Screen",
    "Defensive Investor",
  ];

  const buttons = [
    {
      id: "OVERVIEW",
      label: "OVERVIEW",
      icon: <AssessmentIcon />,
      component: "OverviewContent",
    },
    {
      id: "RETURNS AND RISK",
      label: "RETURNS AND RISK",
      icon: <TrendingUpIcon />,
      component: "ReturnsRiskContent",
    },
    {
      id: "HISTORICAL PRICES",
      label: "HISTORICAL PRICES",
      icon: <FaBuilding />,
      component: "HistoricalPlacesContent",
    },
  ];
  const authCtx = useContext(AuthContext);
  const [authToken, setAuthToken] = useState(null);
  const [strategyData, setStrategyData] = useState([]);
  const [years, setYears] = useState([]);
  const [bestWorstData, setBestWorstData] = useState([]);
  const [selectedStrategy, setSelectedStrategy] = useState(null);
  // const [showVisualData, setShowVisualData] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState("");
  const [bestWorstDataCopy, setBestWorstDataCopy] = useState([]);
  const [selectedSort, setSelectedSort] = useState(0);
  const [selectedField, setSelectedField] = useState();

  const [perExchangeKPI, setPerExhangeKPI] = useState([]);
  const [perSectorKPI, setPerSectorKPI] = useState([]);
  const [perMarketKPI, setPerMarketKPI] = useState([]);
  const [passingCriteria, setPassingCriteria] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentRowsPerPage, setCurrentRowsPerPage] = useState(3);
  const [graphTableData, setGraphTableData] = useState([]);
  const [graphTableDataCopy, setGraphTableDataCopy] = useState([]);
  const [totalPages, setTotalPages] = useState(null);
  const [strategiesCopy, setStrategiesCopy] = useState([]);
  const [chartSwitch, setChartSwitch] = useState(true);
  const [mapsData, setMapsData] = useState([]);
  // const [activeButton, setActiveButton] = useState("OVERVIEW");

  const handleChartSwitchChange = () => {
    setChartSwitch(!chartSwitch);
  };

  const { isSwitch1, setIsSwitch1, isSwitch2, setIsSwitch2 } = useSwitch();

  const rowsPerPageOptions = [3, 5, 10];

  useEffect(() => {
    const CheckUserSession = () => {
      return authCtx.isLoggedIn ? authCtx.token : "";
    };

    const userToken = CheckUserSession();
    setAuthToken(userToken);
  }, []);

  useEffect(() => {
    const fetchStrategyAnnualPerformance = async () => {
      try {
        const response = await fetch(
          Constants.BACKEND_SERVER_BASE_URL +
            "/strategies/getStrategiesAnnualPerformance",
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        const data = await response.json();
        // console.log("test",response);
        if (response.status === 200) {
          console.log("Data:", data);
          setStrategyData(data?.strategies);

          // console.log("strategydata",strategyData);
        } else {
          console.log("Unexpected status code:", response.status);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    const fetchStrategyBestWorstPerformance = async () => {
      try {
        const response = await fetch(
          Constants.BACKEND_SERVER_BASE_URL +
            "/strategies/getStrategiesBestWorstPerformance",
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        const data = await response.json();

        if (response.status === 200) {
          console.log("Data:", data);
          setBestWorstData(data.data);
          setBestWorstDataCopy(data.data);
        } else {
          console.log("Unexpected status code:", response.status);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    const fetchMapsData = async () => {
      try {
        console.log(selectedStrategy);
        const body = {
          strategy_name: selectedStrategy,
        };
        const response = await fetch(
          Constants.BACKEND_SERVER_BASE_URL +
            "/strategies/getStrategyCountryData",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
            body: JSON.stringify(body),
          }
        );

        const data = await response.json();

        if (response.status === 200) {
          console.log("Company", data.data);
          setMapsData(data.data);
          console.log("Countries Data", data.data);
        } else {
          console.log("Unexpected status code:", response.status);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    if (authToken) {
      fetchStrategyAnnualPerformance();
      fetchStrategyBestWorstPerformance();
      fetchMapsData();
    }
  }, [authToken, selectedStrategy]);

  useEffect(() => {
    if (strategyData && strategyData?.length > 0) {
      const firstStrategy = strategyData[0];
      const strategyKeys = Object.keys(firstStrategy);
      const filteredYears = strategyKeys.filter(
        (key) => key !== "strategy_name_here" && key !== "strategy_label"
      );
      setYears(filteredYears);
    }
  }, [strategyData]);

  console.log(years);
  console.log(bestWorstData);

  console.log(strategyData);

  const fetchGraphData = async () => {
    try {
      const body = {
        strategy_name: selectedStrategy,
      };
      const response = await fetch(
        `
            https://api.invelps.com/api/strategies/getStrategyGraphData`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify(body),
        }
      );

      const data = await response.json();

      if (response.status === 200) {
        console.log(data);
        setPerExhangeKPI(data.data.companies_per_exchanges_KPI);
        setPerSectorKPI(data.data.companies_per_sector_KPI);
        setPerMarketKPI(data.data.companies_per_market_cap_KPI);
      } else {
        console.log("Unexpected status code:", response.status);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchGraphTableData = async () => {
    try {
      console.log(selectedStrategy);
      const body = {
        strategy_name: selectedStrategy,
        page: currentPage,
        data_per_page: currentRowsPerPage,
      };
      const response = await fetch(
        `https://api.invelps.com/api/strategies/getStrategyTableData`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify(body),
        }
      );

      const data = await response.json();

      if (response.status === 200) {
        console.log(data.data);
        setGraphTableData(data.data);
        setGraphTableDataCopy(data.data);
        setTotalPages(data.paginator.total_pages);
        setPassingCriteria(data.companies_passing_criteris);
      } else {
        console.log("Unexpected status code:", response.status);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(1, prevPage - 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(totalPages, prevPage + 1));
  };

  const handleChangeRowsPerPage = (event) => {
    setCurrentRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1); // Reset page to 1 when changing rowsPerPage
  };

  useEffect(() => {
    if (selectedStrategy !== null) {
      fetchGraphData();
      fetchGraphTableData();
    }
  }, [selectedStrategy, currentPage, currentRowsPerPage]);

  const handleDataVisualization = (strategy) => {
    console.log(strategy);
    setShowVisualData(!showVisualData);
    setIsSwitch2(true);
    // setSelectedStrategy(strategy.strategy_name_here);
    setSelectedStrategy(strategy);
    setSelectedLabel(strategy.strategy_label);
  };

  const handleSortChange = (e) => {
    setSelectedSort(e.target.value);
  };

  const handleSortingFieldChange = (field) => {
    setSelectedField(field);
  };

  const sorting = (data) => {
    console.log(data);
    console.log(selectedSort);
    if (data) {
      switch (selectedSort) {
        case 0:
          return bestWorstDataCopy;
        case 1:
          return data.slice().sort((a, b) => {
            const valueA = a[selectedField];
            const valueB = b[selectedField];
            console.log(valueA, valueB);

            const alphabetRegex = /[a-zA-Z]/;
            if (alphabetRegex.test(valueA.toString())) {
              return valueA.localeCompare(valueB, undefined, { numeric: true });
            } else {
              return parseFloat(valueA) - parseFloat(valueB);
            }
          });
        case 2:
          return data.slice().sort((a, b) => {
            const valueA = a[selectedField];
            const valueB = b[selectedField];

            const alphabetRegex = /[a-zA-Z]/;
            if (alphabetRegex.test(valueA.toString())) {
              return valueB.localeCompare(valueA, undefined, { numeric: true });
            } else {
              return parseFloat(valueB) - parseFloat(valueA);
            }
          });
        default:
          return data;
      }
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (selectedSort !== 0 && isMounted) {
      const sorted = sorting(strategiesCopy);
      setStrategiesCopy(sorted);

      if (isSwitch2) {
        const sorted = sorting(graphTableDataCopy);
        setGraphTableDataCopy(sorted);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [selectedSort, selectedField, selectedLabel]);

  console.log("startegyData", selectedLabel);
  // console.log('selected', selectedStrategy);

  return showVisualData && isSwitch2 ? (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        maxWidth: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "auto",
        backgroundColor: "white",
        zIndex: 1,
        overflowX: "hidden",
        overflowY: "auto",
      }}
    >
      {/* <Box ml={2} mb={4}>
        <Typography color={"rgba(0, 0, 0, 0.6)"}>
          <span
            onClick={() => {
              setShowVisualData(!showVisualData);
              setIsSwitch2(false);
            }}
            style={{ cursor: "pointer" }}
          >
            {" "}
            Strategies Overview{" "}
          </span>{" "}
          / {selectedLabel}
        </Typography>
      </Box> */}

      <Breadcrumbs separator={">"} aria-label="breadcrumb" sx={{ ml: 2 }}>
        <Typography
          onClick={() => {
            setShowVisualData(false);
            setIsSwitch2(false);
          }}
          style={{ cursor: "pointer" }}
          color="inherit"
        >
          Strategies Overview
        </Typography>

        <Typography
          sx={{ color: "#427879", fontWeight: "bold" }}
          color="inherit"
        >
          {activeButton}
        </Typography>
        <Typography
          sx={{ color: "#427879", fontWeight: "bold" }}
          color="inherit"
        >
          {selectedLabel}
        </Typography>
      </Breadcrumbs>

      <Box padding={2} display={"flex"} gap={3}>
        {buttons.map((button) => (
          <Button
            key={button.id}
            sx={{
              backgroundColor: activeButton === button.id ? "#427879" : "white",
              color: activeButton === button.id ? "white" : "black",
              "&:hover": {
                backgroundColor:
                  activeButton === button.id ? "#427879" : "#427879",
                color: activeButton === button.id ? "white" : "white",
              },
            }}
            startIcon={button.icon}
            onClick={() => {
              setActiveButton(button.id);
              setIsSwitch2(true);
            }}
          >
            {button.label}
          </Button>
        ))}
      </Box>
      <Button
        onClick={() => {
          setShowVisualData(!showVisualData);
          setIsSwitch2(false);
        }}
        sx={{
          alignSelf: "flex-start",
          backgroundColor: "#407879",
          color: "rgb(204, 191, 144)",
          ml: 2,
          mb: 2,
          mt: 3,
        }}
      >
        Back
      </Button>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: 1,
        }}
      >
        <text style={{ fontSize: 25, fontWeight: "bold" }}>
          {" "}
          {selectedLabel}{" "}
        </text>
        <Box
          sx={{
            display: "grid",
            justifyContent: "space-around",
            gridTemplateColumns: {
              xs: "1fr",
              sm: "1fr",
              md: "1.5fr 1fr",
            },
            gap: 2,
            my: 2,
          }}
        >
          <Card
            sx={{
              padding: 4,
              gap: 3,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <text style={{ fontWeight: "bolder" }}> Companies Per Country</text>
            {/* <PieChart
                    graphData={perExchangeKPI}
                    nameData={(item) => item.exchange}
                  /> */}
            <GeoChartComponent data={mapsData} />
          </Card>
          <Box style={{ display: "flex", gap: 6, flexDirection: "column" }}>
            <Card
              sx={{
                padding: 2,
              }}
            >
              <text style={{ fontWeight: "bolder" }}>
                {" "}
                Companies Per Sector (%){" "}
              </text>
              <HorizontalBarChart data={perSectorKPI} />
            </Card>
            <Card
              sx={{
                padding: 4,
                paddingBottom: { xs: 8, md: 4 },
                display: "flex",
                flexDirection: "column",
                height: 250,
              }}
            >
              <text style={{ fontWeight: "bolder" }}>
                {" "}
                Companies Per Market Cap (%){" "}
              </text>
              <DonutPieChart
                data={perMarketKPI}
                dataKey={"total_count"}
                nameKey={"market_cap_class"}
              ></DonutPieChart>
              {/* <PieChart
                    graphData={perMarketKPI}
                    nameData={(item) => item.market_cap_class}
                  /> */}
            </Card>
          </Box>
        </Box>
        <Card
          sx={{
            width: "100%",
            height: "100%",
            margin: 0,
            gap: 5,
            padding: 1,
          }}
        >
          <Box justifyContent={"space-between"} display={"flex"}>
            <text style={{ fontSize: 20, fontWeight: "bold" }}>
              Companies Passing Criterias:{" "}
              <span style={{ color: "gray" }}>{passingCriteria}</span>
            </text>
          </Box>
          <TableContainer>
            <Table
              sx={{ width: "100%", maxWidth: "100%", mt: 1 }}
              size="medium"
            >
              <TableHead>
                <TableRow>
                  {passingHeadCells.data.map((headCell, index) => (
                    <StyledTableCell key={index} padding="normal">
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 2,
                        }}
                      >
                        <span>{headCell.label}</span>
                        {/* {headCell.key.trim() !== "" &&
                            (selectedSort === 1 ? (
                              <button
                                onClick={() => {
                                  handleSortingFieldChange(headCell.key);
                                  setSelectedSort(2);
                                }}
                                style={{
                                  color: "black",
                                  background: "rgba(255, 255, 255, 0.3)",
                                  border: "none",
                                  borderRadius: "9999px",
                                  width: "24px",
                                  height: "24px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <IoArrowDown />
                              </button>
                            ) : (
                              <button
                                style={{
                                  color: "black",
                                  background: "rgba(255, 255, 255, 0.3)",
                                  border: "none",
                                  borderRadius: "9999px",
                                  width: "24px",
                                  height: "24px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                                onClick={() => {
                                  handleSortingFieldChange(headCell.key);
                                  setSelectedSort(1);
                                }}
                              >
                                <IoArrowUp />
                              </button>
                            ))} */}
                      </Box>
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              {graphTableDataCopy ? (
                <TableBody>
                  {graphTableDataCopy.map((data, index) => {
                    return (
                      <Tooltip
                        key={index}
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                        title="Click to analyze the company"
                      >
                        <StyledTableRow
                          hover
                          onClick={() => {
                            console.log(isSwitch1);
                            console.log(isSwitch2);
                            setSelectedCompany(data);
                            setIsSwitch1(true);
                            setIsSwitch2(false);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <StyledTableCell>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 5,
                              }}
                            >
                              <img
                                src={data.image}
                                style={{ height: "30px", width: "35px" }}
                              />
                              {data.company_name}
                            </Box>
                          </StyledTableCell>

                          <StyledTableCell> {data.symbol} </StyledTableCell>
                          <StyledTableCell> {data.exchange} </StyledTableCell>
                          <StyledTableCell> {data.sector} </StyledTableCell>
                          <StyledTableCell> {data.industry} </StyledTableCell>
                          <StyledTableCell
                            sx={{
                              color: data.total_return >= 0 ? "green" : "red",
                            }}
                          >
                            {" "}
                            {data.total_return}{" "}
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{
                              color:
                                data.annualized_return >= 0 ? "green" : "red",
                            }}
                          >
                            {" "}
                            {data.annualized_return}{" "}
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{
                              color: data.rolling_return >= 0 ? "green" : "red",
                            }}
                          >
                            {" "}
                            {data.rolling_return}{" "}
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{
                              color:
                                data.stdev_excess_return >= 0 ? "green" : "red",
                            }}
                          >
                            {" "}
                            {data.stdev_excess_return}{" "}
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{
                              color: data.max_drawdown >= 0 ? "green" : "red",
                            }}
                          >
                            {" "}
                            {data.max_drawdown}{" "}
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{
                              color: data.sharpe_ratio >= 0 ? "green" : "red",
                            }}
                          >
                            {" "}
                            {data.sharpe_ratio}{" "}
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{
                              color: data.sortino_ratio >= 0 ? "green" : "red",
                            }}
                          >
                            {" "}
                            {data.sortino_ratio ? data.sortino_ratio : "-"}{" "}
                          </StyledTableCell>
                        </StyledTableRow>
                      </Tooltip>
                    );
                  })}
                </TableBody>
              ) : (
                <CgSpinner size={24} />
              )}
            </Table>
          </TableContainer>
          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            width={"100%"}
            gap={1}
            mt={2}
          >
            <Box display={"flex"} alignItems={"center"} gap={1}>
              <label>Rows Per Page:</label>
              <select
                value={currentRowsPerPage}
                onChange={handleChangeRowsPerPage}
              >
                {rowsPerPageOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </Box>

            <Box display={"flex"} alignItems={"center"} px={2} gap={1}>
              <IconButton onClick={handlePrevPage} disabled={currentPage === 1}>
                <ArrowBack />
              </IconButton>

              <span style={{ fontFamily: "Montserrat" }}>
                Page {currentPage} of {totalPages}
              </span>

              <IconButton
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
              >
                <ArrowForward />
              </IconButton>
            </Box>
          </Box>
        </Card>
      </Card>
    </div>
  ) : (
    <>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "1fr", // single column on small screens
            sm: "1fr 1fr", // two columns on medium screens and larger
          },
          gap: 2, // spacing between grid items
        }}
      >
        <Card sx={{ m: 1 }}>
          <Box p={3}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ mt: 0.5, mr: 1 }}>
                <Typography
                  variant="h6"
                  component="span"
                  sx={{
                    padding: "5px",
                    fontSize: "27px",
                    fontWeight: "bold",
                  }}
                >
                  Annual Returns
                </Typography>
              </Box>

              <Box>
                <label
                  htmlFor="annualDevidendSwitch"
                  style={{ marginLeft: 10 }}
                >
                  Line Chart
                </label>

                <Switch
                  id="annualDevidendSwitch"
                  checked={chartSwitch}
                  onChange={handleChartSwitchChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
                <label htmlFor="annualDevidendSwitch"> Bar Chart</label>
              </Box>
            </Box>

            {/* <div
              style={{
                width: "100%",
              }}
            >
              <Box sx={{padding:2}}>
                <text
                  style={{
                    boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.5)",
                    padding: 2,
                  }}
                >
                  {bestWorstDataCopy[0]?.duration} years
                </text>
              </Box>

              <label htmlFor="annualDevidendSwitch" style={{ marginLeft: 10 }}>
                {" "}
                Line Chart
              </label>
              <Switch
                id="annualDevidendSwitch"
                checked={chartSwitch}
                onChange={handleChartSwitchChange}
                inputProps={{ "aria-label": "controlled" }}
              />
              <label htmlFor="annualDevidendSwitch"> Bar Chart</label>
            </div> */}
            {/* <Box sx={{ padding: 2 }}>
              <text
                style={{
                  boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.5)",
                  padding: 2,
                }}
              >
                {bestWorstDataCopy[0]?.duration} years
              </text>
            </Box> */}

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 3,
                marginTop: 6,
              }}
            >
              <LineRaceChart
                chartId={"LR-chart-1"}
                chartData={strategyData}
                years={years}
                type="returns"
                chartSwitch={chartSwitch}
              />
            </Box>
            <TableContainer>
              <Table
                sx={{ minWidth: "100%", maxWidth: "100%", mt: 1 }}
                size="medium"
              >
                <TableHead>
                  <TableRow
                    sx={{
                      backgroundColor: "#e7ecef",
                      color: "#272727",
                      fontSize: 14,
                    }}
                  >
                    <TableCell sx={{ fontFamily: "Montserrat" }}>
                      Strategy
                    </TableCell>
                    <TableCell sx={{ fontFamily: "Montserrat" }}>
                      Trends
                    </TableCell>
                    {years.map((year, index) => (
                      <TableCell
                        key={index}
                        padding="normal"
                        sx={{ fontFamily: "Montserrat", color: "#427878" }}
                      >
                        {year}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {strategyData !== null &&
                    strategyData?.map((strategy, index) => (
                      <StyledTableRow hover key={index} sx={{ ml: 3 }}>
                        <StyledTableCell
                          onClick={() => {
                            handleDataVisualization(strategy);
                            setSelectedSort(2);
                          }}
                          sx={{
                            cursor: "pointer",
                            ":hover": {
                              textDecoration: "underline",
                              color: "blue",
                            },
                          }}
                        >
                          {/* strategy button */}
                          {strategy?.strategy_label}
                        </StyledTableCell>
                        <StyledTableCell>
                          {(strategy[years[years.length - 1]]?.anual_return -
                            strategy[years[1]]?.anual_return) /
                            (years[years.length - 1] - years[1]) >=
                          0 ? (
                            <IoArrowUpOutline color="green" size={18} />
                          ) : (
                            <IoArrowDown color="red" size={18} />
                          )}
                        </StyledTableCell>

                        {years.map((year, index) => (
                          <StyledTableCell
                            key={index}
                            sx={{
                              color:
                                parseFloat(strategy[year]?.anual_return) >= 0
                                  ? "green"
                                  : "red",
                            }}
                          >
                            {strategy[year]?.anual_return
                              ? strategy[year]?.anual_return
                              : "-"}
                          </StyledTableCell>
                        ))}
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Card>
        <Card sx={{ m: 1, position: "relative" }}>
          <Box p={3}>
            <Box spacing={1} sx={{ mt: 0.5 }}>
              <text
                style={{
                  padding: "5px",
                  fontSize: "27px",
                  fontWeight: "bold",
                  fontFamily: "Montserrat",
                }}
              >
                Rolling Return
              </text>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 3,
                marginTop: 6,
              }}
            >
              <NegativeBarChart
                chartId={"Neg-chart-1"}
                chartData={bestWorstData}
              />
            </Box>
            <TableContainer>
              <Table
                sx={{
                  minWidth: "100%",
                  maxWidth: "100%",
                  mt: 2,
                  fontFamily: "Montserrat",
                }}
                size="medium"
              >
                {/* <TableHead>
                <TableRow>
                  <TableCell
                    padding="normal"
                    colSpan={1}
                    sx={{
                      backgroundColor: "#272727",
                      color: "white",
                      fontSize: 18,
                      fontFamily: "Montserrat",
                    }}
                  >
                    Strategy Models ({bestWorstDataCopy[0]?.duration} years)
                  </TableCell>
                  <TableCell
                    padding="normal"
                    colSpan={12}
                    align="center"
                    sx={{
                      backgroundColor: "#427878",
                      color: "white",
                      fontSize: 18,
                      fontFamily: "Montserrat",
                    }}
                  >
                    Rolling Returns (%)
                  </TableCell>
                </TableRow>
              </TableHead> */}

                <TableHead>
                  <TableRow
                    sx={{
                      backgroundColor: "#e7ecef",
                      color: "#272727",
                      fontSize: 14,
                    }}
                  >
                    <TableCell>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 2,
                          fontFamily: "Montserrat",
                        }}
                      >
                        <span>Strategy</span>
                        {/* {selectedSort === 1 ? (
                          <button
                            onClick={() => {
                              handleSortingFieldChange("name");
                              setSelectedSort(2);
                            }}
                            style={{
                              color: "white",
                              background: "rgba(0, 0, 0, 0.3)",
                              border: "none",
                              borderRadius: "9999px",
                              width: "24px",
                              height: "24px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <IoArrowDown />
                          </button>
                        ) : (
                          <button
                            style={{
                              color: "white",
                              background: "rgba(0, 0, 0, 0.3)",
                              border: "none",
                              borderRadius: "9999px",
                              width: "24px",
                              height: "24px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            onClick={() => {
                              handleSortingFieldChange("name");
                              setSelectedSort(1);
                            }}
                          >
                            <IoArrowUp />
                          </button>
                        )} */}
                      </Box>
                    </TableCell>
                    {headCategories.map((category, index) => (
                      <TableCell key={index} padding="normal">
                        <Box
                          sx={{ display: "flex", alignItems: "center", gap: 2 }}
                        >
                          <span>{category.title}</span>
                          {/* {category.key.trim() !== "" &&
                            (selectedSort === 1 ? (
                              <button
                                onClick={() => {
                                  handleSortingFieldChange(category.key);
                                  setSelectedSort(2);
                                }}
                                style={{
                                  color: "white",
                                  background: "rgba(0, 0, 0, 0.3)",
                                  border: "none",
                                  borderRadius: "9999px",
                                  width: "24px",
                                  height: "24px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <IoArrowDown />
                              </button>
                            ) : (
                              <button
                                style={{
                                  color: "white",
                                  background: "rgba(0, 0, 0, 0.3)",
                                  border: "none",
                                  borderRadius: "9999px",
                                  width: "24px",
                                  height: "24px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                                onClick={() => {
                                  handleSortingFieldChange(category.key);
                                  setSelectedSort(1);
                                }}
                              >
                                <IoArrowUp />
                              </button>
                            ))} */}
                        </Box>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bestWorstDataCopy.map((data, index) => (
                    <StyledTableRow hover key={index} sx={{ ml: 3 }}>
                      <StyledTableCell
                        onClick={() => handleDataVisualization(data.name)}
                        sx={{
                          cursor: "pointer",
                          ":hover": {
                            textDecoration: "underline",
                            color: "blue",
                          },
                        }}
                      >
                        {data.strategy_label}
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{
                          color:
                            parseFloat(data.rolling_return) >= 0
                              ? "green"
                              : "red",
                        }}
                      >
                        {data.rolling_return}
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{
                          color:
                            parseFloat(data.best_return) >= 0 ? "green" : "red",
                        }}
                      >
                        {data.best_return}
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{
                          color:
                            parseFloat(data.worst_return) >= 0
                              ? "green"
                              : "red",
                        }}
                      >
                        {data.worst_return}
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{
                          color:
                            parseFloat(data.negative_annual_returns) >= 0
                              ? "green"
                              : "red",
                        }}
                      >
                        {data.negative_annual_returns}
                      </StyledTableCell>
                      {/* <StyledTableCell
                      sx={{
                        color: parseFloat(data.duration) >= 0 ? "green" : "red",
                      }}
                    >
                      {data.duration}
                    </StyledTableCell> */}
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Card>
      </Box>
    </>
  );
};

export default ReturnsTab;
