export const kpi =[
  {
    strategy_name: "Revised_Fast_Growers",
    exchange: "National Stock Exchange Of India",
    total_count: 13.913,
  },
  {
    strategy_name: "Revised_Fast_Growers",
    exchange: "Taipei Exchange",
    total_count: 4.34783,
  },
  {
    strategy_name: "Revised_Fast_Growers",
    exchange: "NASDAQ Global Market",
    total_count: 8.69565,
  },
  {
    strategy_name: "Revised_Fast_Growers",
    exchange: "Athens Stock Exchange",
    total_count: 0.869565,
  },
  {
    strategy_name: "Revised_Fast_Growers",
    exchange: "Six Swiss Exchange",
    total_count: 2.6087,
  },
  {
    strategy_name: "Revised_Fast_Growers",
    exchange: "Hong Kong Exchange",
    total_count: 5.21739,
  },
  {
    strategy_name: "Revised_Fast_Growers",
    exchange: "London Stock Exchange",
    total_count: 3.47826,
  },
  {
    strategy_name: "Revised_Fast_Growers",
    exchange: "Frankfurt Stock Exchange",
    total_count: 8.69565,
  },
  {
    strategy_name: "Revised_Fast_Growers",
    exchange: "Johannesburg Stock Exchange",
    total_count: 2.6087,
  },
  {
    strategy_name: "Revised_Fast_Growers",
    exchange: "New York Stock Exchange",
    total_count: 3.47826,
  },
  {
    strategy_name: "Revised_Fast_Growers",
    exchange: "NASDAQ Capital Market",
    total_count: 0.869565,
  },
  {
    strategy_name: "Revised_Fast_Growers",
    exchange: "Shenzhen Stock Exchange",
    total_count: 13.0435,
  },
  {
    strategy_name: "Revised_Fast_Growers",
    exchange: "Korea Exchange",
    total_count: 0.869565,
  },
  {
    strategy_name: "Revised_Fast_Growers",
    exchange: "Shanghai Stock Exchange",
    total_count: 5.21739,
  },
  {
    strategy_name: "Revised_Fast_Growers",
    exchange: "Nyse Euronext - Euronext Brussels",
    total_count: 0.869565,
  },
  {
    strategy_name: "Revised_Fast_Growers",
    exchange: "Euronext Paris",
    total_count: 0.869565,
  },
  {
    strategy_name: "Revised_Fast_Growers",
    exchange: "Australian Securities Exchange",
    total_count: 2.6087,
  },
  {
    strategy_name: "Revised_Fast_Growers",
    exchange: "Tokyo Stock Exchange",
    total_count: 9.56522,
  },
  {
    strategy_name: "Revised_Fast_Growers",
    exchange: "Bombay Stock Exchange",
    total_count: 10.4348,
  },
  {
    strategy_name: "Revised_Fast_Growers",
    exchange: "Toronto Stock Exchange Ventures",
    total_count: 0.869565,
  },
  {
    strategy_name: "Revised_Fast_Growers",
    exchange: "Madrid Stock Exchange",
    total_count: 0.869565,
  },
];
